import { type Ref, ref } from 'vue'

// TODO разбить на несколько типов?
export interface IModal {
  title?: string // Заголовок
  body?: string // Текст
  component?: any // Можно передать компонент
  componentProps?: any
  events?: any
  actions?: any
}

export const modals: Ref<IModal[] | any[]> = ref([])
export const addModal = (modal: IModal) => modals.value.push(modal);
export const removeModal = () => modals.value.pop().closeCallback?.();
